import { CSSProperties } from 'react';
import { Flex } from '@components';
import { Paragraph } from '@components/typography';
import variables from '@styles/export.module.scss';

type EmptyContainerProps = {
	backgroundColor?: string;
	color?: string;
	style?: CSSProperties;
	text: string;
};

const EmptyContainer = ({
	backgroundColor = variables.gray0,
	color = variables.gray5,
	style,
	text,
	...rest
}: EmptyContainerProps) => {
	return (
		<Flex
			fullWidth
			justify='center'
			align='center'
			style={{ backgroundColor, color, padding: '3.2rem', borderRadius: variables.radius4, ...style }}
			{...rest}
		>
			<Paragraph>{text}</Paragraph>
		</Flex>
	);
};

export default EmptyContainer;
