import { memo } from 'react';
import cn from 'classnames';
import { Chevron } from '@components';
import { Paragraph } from '@components/typography';
import { PaginationPOMS } from '@ts/poms/orders';
import styles from './Pagination.module.scss';

type PaginationProps = {
	handlePagination: (args: number) => void;
	isLoading: boolean;
	pagination: PaginationPOMS;
	pagesLength: number;
};

const Pagination = ({ handlePagination, isLoading, pagination, pagesLength }: PaginationProps) => {
	return (
		<div className={styles.pagination}>
			<ul className={styles.list}>
				<li key='pagination-previous'>
					<button
						disabled={isLoading || !pagination?.prev}
						className={styles.icon}
						onClick={() => handlePagination(pagination?.prev)}
						tabIndex={1}
					>
						<Chevron direction='left' />
					</button>
				</li>
				{isLoading && !pagesLength ? (
					<div className={styles.loading} />
				) : (
					[...Array(pagesLength)].map((_, i) => (
						<li key={`pagination-page-${pagesLength - i}`}>
							<button
								disabled={isLoading}
								className={cn({ [styles.current]: pagination?.page === i + 1 })}
								onClick={() => handlePagination(i + 1)}
								tabIndex={1}
							>
								<Paragraph>{i + 1}</Paragraph>
							</button>
						</li>
					))
				)}
				<li key='pagination-next'>
					<button
						disabled={isLoading || !pagination?.next || pagination?.pages === 1}
						className={styles.icon}
						onClick={() => handlePagination(pagination?.next)}
						tabIndex={1}
					>
						<Chevron direction='right' />
					</button>
				</li>
			</ul>
		</div>
	);
};

export default memo(Pagination);
