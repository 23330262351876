import { ORDER_FILTER_OPTIONS, ORDER_FILTER_PLACEHOLDER } from '@constants';
import { EmptyContainer, Flex, HistoryItem, HistorySkeletonLoader, Pagination, Select, SelectOption, skeletonStyles } from '@components';
import { Heading } from '@components/typography';
import { OrderDataPOMS } from '@ts/poms/orders';
import { useWindowDimensions } from '@utils/hooks';

type HistoryListProps = {
	containerClass: string;
	handleItemClick: (id: string, el?: EventTarget & HTMLDivElement) => void;
	handlePagination: (args: number) => void;
	handleSelect: (selection: SelectOption) => void;
	isLoading: boolean;
	orderData: OrderDataPOMS;
	pagesLength: number;
};

const HistoryList = ({
	containerClass,
	handleItemClick,
	handlePagination,
	handleSelect,
	isLoading,
	orderData,
	pagesLength,
}: HistoryListProps) => {
	const { width } = useWindowDimensions();
	const hasAccountData = !!orderData?.orders.length;
	const isDesktopView = width > 768;

	let historyList = null;

	if (isLoading) historyList = <HistorySkeletonLoader />;
	else {
		if (hasAccountData) {
			orderData.orders.length
				? (historyList = orderData?.orders.map((order, index) => (
					<HistoryItem key={`order-${index}`} order={order} handleItemClick={handleItemClick} />
				)))
				: (historyList = <EmptyContainer text='No orders within this time period!' />);
		} else historyList = <EmptyContainer text='You have no orders!' />;
	}

	return (
		<>
			<Flex column fullWidth fullHeight justify='start' align='center' gap={4} className={containerClass}>
				<Flex fullWidth align='center' justify={isDesktopView ? 'start' : 'between'}>
					<Heading tag='h4' style={{ paddingRight: '2.4rem', margin: '0', display: 'flex' }}>
						{isLoading && !orderData ? (
							<span className={skeletonStyles['skeleton-loader-length']} />
						) : (
							orderData?.pagination.count || 0
						)}{' '}
						order{orderData?.orders.length !== 1 && 's'} placed
					</Heading>
					<Select
						buttonProps={{ size: isDesktopView ? 'large' : 'small' }}
						handler={handleSelect}
						values={ORDER_FILTER_OPTIONS}
						placeholder={ORDER_FILTER_PLACEHOLDER}
					/>
				</Flex>
				{historyList}
			</Flex>
			<Pagination
				handlePagination={handlePagination}
				isLoading={isLoading}
				pagination={orderData?.pagination}
				pagesLength={pagesLength}
			/>
		</>
	);
};

export default HistoryList;
